export const PROJECT_ID = 'onlyone-pro-prod';
export const CLIENT_ID = '490844017905-fsei4mi3kbu247hlsvme0b3o8nq5pjec.apps.googleusercontent.com';

export const BASE_URL = 'https://api.onlyonecard.pro';
export const REDIRECT_URL = 'https://backend.onlyonecard.pro';
export const API_KEY = 'AIzaSyBbpkwWzV9tw0Wh3nbHQU_ayFLSYce12LQ';
export const BACKEND_API_BASE_URL = 'https://jarvis.onlyonecard.pro';
export const FRIDAY_API_BASE_URL = 'https://friday.onlyonecard.pro';
export const CEREBRO_API_BASE_URL = 'https://cerebro.onlyonecard.pro';
export const SMAUG_API_BASE_URL = 'https://smaug.onlyonecard.pro';
export const SIDEKICK_API_BASE_URL = 'https://sidekick.onlyonecard.pro';
export const NOTIF_CENTER_API_BASE_URL = 'https://notif-center.onlyonecard.pro';
export const SAURON_API_BASE_URL = 'https://sauron.onlyonecard.pro';
export const SCROOGE_API_BASE_URL = 'https://scrooge.onlyonecard.pro';

export const ONLYONE_WEB_APP_BASE_URL = 'https://app.onlyonecard.pro';
